import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import imagePaymentDetails from "../../assets/images/payment/payment-methods.png";
import configData from "../config/index.json";
import {
  applePaySessionURL,
  ekashuFormRequest,
  performTransactionRequest,
  registerGooglePaymentToken,
} from "../../services/api";
import GooglePayButton from "@google-pay/button-react";
import { Button } from "@material-ui/core";
import AppleIcon from "@material-ui/icons/Apple";
import IconButton from "@material-ui/core/IconButton";

export default function PaymentDetails() {
  const classes = useStyles();
  const purchase_amount = "0";
  const GOOGLE_PAY_MERCHANT_NAME = "Gatwick Airport";
  const [canRunApplePay, setCanRunApplePay] = useState(false);
  const [parametersArray, setParametersArray] = useState({});
  const [cartURL, setCartURL] = useState("");
  const frontEndURL = "https://dropoff.gatwickairport.com";
  // const frontEndURL = 'http://localhost:3000'

  const config = {
    payments: {
      acceptedCardSchemes: ["amex", "masterCard", "maestro", "visa", "mada"],
    },
    shop: {
      product_price: purchase_amount,
      shop_name: GOOGLE_PAY_MERCHANT_NAME,
      shop_localisation: {
        currencyCode: "GBP",
        countryCode: "GB",
      },
    },
    shipping: {
      GB_region: [
        {
          label: "Free Shipping",
          amount: "0.00",
          detail: "Arrives in 3-5 days",
          identifier: "freeShipping",
        },
        {
          label: "Express Shipping",
          amount: "5.00",
          detail: "Arrives in 1-2 days",
          identifier: "expressShipping",
        },
      ],
      WORLDWIDE_region: [
        {
          label: "Worldwide Standard Shipping",
          amount: "10.00",
          detail: "Arrives in 5-8 days",
          identifier: "worldwideShipping",
        },
      ],
    },
  };

  useEffect(() => {
    canItRunApplePay();
    payWithCreditClicked();
  }, []);

  function canItRunApplePay() {
    // @ts-ignore
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      setCanRunApplePay(true);
      return;
    }

    setCanRunApplePay(false);
  }

  function payWithApplePay() {
    // @ts-ignore
    const session = new ApplePaySession(6, {
      currencyCode: config.shop.shop_localisation.currencyCode,
      countryCode: config.shop.shop_localisation.countryCode,
      merchantCapabilities: [
        "supports3DS",
        "supportsEMV",
        "supportsCredit",
        "supportsDebit",
      ],
      supportedNetworks: config.payments.acceptedCardSchemes,
      shippingType: "shipping",
      requiredBillingContactFields: ["postalAddress", "name", "phone", "email"],
      requiredShippingContactFields: [
        "postalAddress",
        "name",
        "phone",
        "email",
      ],
      total: {
        label: config.shop.shop_name,
        amount: config.shop.product_price,
        type: "final",
      },
    });

    console.log("session.appleSession", session);

    handleApplePayEvents(session);
    // @ts-ignore
    session.begin();
    console.log("session begin");
  }

  async function handleApplePayEvents(appleSession: any) {
    console.log("appleSession", appleSession);

    // This is the first event that Apple triggers. Here you need to validate the
    // Apple Pay Session from your Back-End
    appleSession.onvalidatemerchant = function (event: any) {
      console.log("hello");
      console.log("event.validationURL", event.validationURL);
      const merchantSession = validateApplePaySession(event.validationURL);
      console.log("merchantSession", merchantSession);
      appleSession.completeMerchantValidation(merchantSession);
    };

    // This method is triggered before populating the shipping methods. This is the
    // perfect place inject your shipping methods
    appleSession.onshippingcontactselected = (event: any) => {
      // populate with the availbale shipping methods for the region (Apple will tell you the region).
      // while the full address will only be available to you after the user confirms tha payment
      var shipping = getAvailableShippingMethods(
        config.shop.shop_localisation.countryCode
      );
      // Update total and line items based on the shipping methods
      var newTotal = {
        type: "final",
        label: config.shop.shop_name,
        amount: calculateTotal(
          config.shop.product_price,
          shipping.methods[0].amount
        ),
      };
      var newLineItems = [
        {
          type: "final",
          label: "Subtotal",
          amount: config.shop.product_price,
        },
        {
          type: "final",
          label: shipping.methods[0].label,
          amount: shipping.methods[0].amount,
        },
      ];
      appleSession.completeShippingContactSelection(
        // @ts-ignore
        ApplePaySession.STATUS_SUCCESS,
        shipping.methods,
        newTotal,
        newLineItems
      );
    };

    // This method is triggered when a user select one of the shipping options.
    // Here you generally want to keep track of the transaction amount
    appleSession.onshippingmethodselected = (event: any) => {
      var newTotal = {
        type: "final",
        label: config.shop.shop_name,
        amount: calculateTotal(
          config.shop.product_price,
          event.shippingMethod.amount
        ),
      };
      var newLineItems = [
        {
          type: "final",
          label: "Subtotal",
          amount: config.shop.product_price,
        },
        {
          type: "final",
          label: event.shippingMethod.label,
          amount: event.shippingMethod.amount,
        },
      ];
      appleSession.completeShippingMethodSelection(
        // @ts-ignore
        ApplePaySession.STATUS_SUCCESS,
        newTotal,
        newLineItems
      );
    };

    // This method is the most important method. It gets triggered after the user has
    // confirmed the transaction with the Touch ID or Face ID. Besides getting all the
    // details about the customer (email, address ...) you also get the Apple Pay payload
    // needed to perform a payment.
    appleSession.onpaymentauthorized = (event: any) => {
      const outcome: any = performTransaction(event.payment);

      if (outcome.approved) {
        // @ts-ignore
        appleSession.completePayment(ApplePaySession.STATUS_SUCCESS);
        console.log(outcome);
      } else {
        // @ts-ignore
        appleSession.completePayment(ApplePaySession.STATUS_FAILURE);
        console.log(outcome);
      }
    };

    async function validateApplePaySession(url: any) {
      try {
        let data = {
          apikey: configData.APIKEY,
          userlocale: configData.USERLOCALE,
          validation_url: url,
        };

        const response = await applePaySessionURL(data);

        console.log("validateApplePaySession response", response.data);

        return response.data.data;
      } catch (error) {
        console.log("validateApplePaySession error", error);
      }
    }

    function getAvailableShippingMethods(region: any) {
      // return the shipping methods available based on region
      if (region === "GB") {
        return { methods: config.shipping.GB_region };
      } else {
        return { methods: config.shipping.WORLDWIDE_region };
      }
    }
    function calculateTotal(subtotal: any, shipping: any) {
      return (parseFloat(subtotal) + parseFloat(shipping)).toFixed(2);
    }

    async function performTransaction(details: any) {
      // I'm using AXIOS to do a POST request to the backend but any HTTP client can be used

      try {
        let data = {
          token: details.token,
          customerEmail: details.shippingContact.emailAddress,
          billingDetails: details.billingContact,
          shippingDetails: details.shippingContact,
        };

        const response = await performTransactionRequest(data);

        return response;
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  async function registerGoogleGatewayPaymentToken(payment_token: any) {
    try {
      let data = {
        payment_token: payment_token,
        amount: purchase_amount,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      };
      const response = await registerGooglePaymentToken(data);

      if (response.data.status === "success") {
      }

      console.log("response", response.data);
    } catch (error) {
      console.log("error", error);
    }
  }

  async function payWithCreditClicked() {
    try {
      let data = {
        success_url: `${frontEndURL}/finishedRegister`,
        failure_url: `${frontEndURL}/paymentdetails`,
        return_url: `${frontEndURL}/paymentdetails`,
        description: "Gatwick Airport - AutoPay - Add Card",
        userlocale: configData.USERLOCALE,
        apikey: configData.APIKEY,
        amount: purchase_amount,
      };

      const response = await ekashuFormRequest(data);

      if (response.data.status === "success") {
        setParametersArray(response.data.data.parametersarray);
        setCartURL(response.data.data.cart_url);
      }

      console.log("response", response.data);
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        justify={"center"}
        alignItems="center"
        direction="row"
      >
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <label
              style={{ fontSize: 13, fontWeight: "bolder", color: "#66686a" }}
            >
              Step 2 of 2
            </label>
            <Typography variant="h6" className={classes.containerTitle}>
              Payment details
            </Typography>
            <div className={classes.topDivider} />
          </Paper>
        </Grid>
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <img src={imagePaymentDetails} alt="imagePaymentDetails" />
          </Paper>
        </Grid>
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <div style={{ marginTop: 20 }}>
              <div className={classes.paymentStyle}>
                <form
                  id="cartform"
                  method="POST"
                  action={cartURL}
                  target="_parent"
                >
                  {Object.entries(parametersArray).map((value) => {
                    return (
                      <input
                        type="hidden"
                        key={value[0]}
                        name={value[0]}
                        id={value[0]}
                        // @ts-ignore
                        value={value[1] === null ? "" : value[1]}
                      />
                    );
                  })}

                  <input
                    type="submit"
                    value="Pay with Debit/Credit Card"
                    style={{
                      cursor: "pointer",
                      background: "#004489",
                      borderColor: "#004589",
                      width: "240px",
                      height: 40,
                      borderRadius: 4,
                      color: "white",
                      fontWeight: "bold",
                      fontSize: 16,
                      borderWidth: 1,
                    }}
                  />
                </form>

                <GooglePayButton
                  className={classes.googlePay}
                  environment="PRODUCTION"
                  paymentRequest={{
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [
                      {
                        type: "CARD",
                        parameters: {
                          allowedAuthMethods: ["CRYPTOGRAM_3DS"],
                          allowedCardNetworks: [
                            "AMEX",
                            "DISCOVER",
                            "INTERAC",
                            "JCB",
                            "MASTERCARD",
                            "VISA",
                          ],
                        },
                        tokenizationSpecification: {
                          type: "PAYMENT_GATEWAY",
                          parameters: {
                            gateway: "gatewayservices",
                            gatewayMerchantId: "25057313",
                          },
                        },
                      },
                    ],
                    merchantInfo: {
                      merchantId: "6954-5263-7142",
                      merchantName: "Gatwick Airport",
                    },
                    transactionInfo: {
                      totalPriceStatus: "FINAL",
                      totalPriceLabel: "Total",
                      totalPrice: purchase_amount,
                      currencyCode: config.shop.shop_localisation.currencyCode,
                      countryCode: config.shop.shop_localisation.countryCode,
                    },
                  }}
                  onLoadPaymentData={(paymentRequest) =>
                    registerGoogleGatewayPaymentToken(
                      paymentRequest.paymentMethodData.tokenizationData.token
                    )
                  }
                />
              </div>

              {canRunApplePay && (
                <div className={classes.applePay}>
                  <Button
                    style={{
                      width: 240,
                      marginTop: 20,
                      height: 40,
                      backgroundColor: "black",
                      color: "white",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                    onClick={() => payWithApplePay()}
                  >
                    Pay with
                    <IconButton aria-label="delete" style={{ padding: 0 }}>
                      <AppleIcon />
                    </IconButton>
                    Pay
                  </Button>
                </div>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      "@media (max-width: 675px)": {
        marginTop: 120,
      },
      marginTop: 130,
      //   backgroundColor: 'red',
    },
    gridContainer: {
      "@media (max-width: 675px)": {
        width: "100%",
      },
      width: "70%",
    },
    paper: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    wrapper: {
      "@media (max-width: 675px)": {
        width: 150,
      },
      width: 210,
    },
    containerTitle: {
      fontWeight: "bolder",
      "@media (max-width: 675px)": {
        fontSize: 20,
      },
      fontSize: 30,
      color: "black",
    },
    topDivider: {
      backgroundColor: "#F3F3F3",
      height: 1,
      marginTop: 15,
      marginBottom: 15,
    },
    paymentStyle: {
      "@media (max-width: 675px)": {
        display: "grid",
        justifyContent: "center",
      },
      display: "flex",
    },
    googlePay: {
      "@media (max-width: 675px)": {
        marginLeft: 0,
        marginTop: 20,
      },
      marginLeft: 50,
    },
    media: {
      height: 38,
      width: 38,
    },
    applePay: {
      "@media (max-width: 675px)": {
        display: "flex",
        justifyContent: "center",
      },
    },
  })
);
