import React, { useEffect, useState } from "react";
import { styles } from "../styles";
import Grid from "@material-ui/core/Grid";
import { BodyHeader, BottomButtons } from "../../components";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import configData from "../config/index.json";

import {
  ekashuFormRequest,
  registerGooglePaymentToken,
  removePaymentMethod,
  requestPaymentMethod,
} from "../../services/api";
import GooglePayButton from "@google-pay/button-react";
import Button from "@material-ui/core/Button";
import mastercard from "../../assets/card/mastercard.png";
import visa from "../../assets/card/visa.png";
import diners from "../../assets/card/diners.png";
import amex from "../../assets/card/amex.png";
import jcb from "../../assets/card/jcb.png";
import discover from "../../assets/card/discover.png";
// import interac from '../../assets/card/interac.png'
import googlePayIcon from "../../assets/icons/google_pay.png";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import ApplePay from "../../components/ApplePay/ApplePay";

export function useForceUpdate() {
  const [, setIt] = useState(false);
  return () => setIt((it) => !it);
}

export default function EditPaymentDetails() {
  const classes = useStyles();
  const purchase_amount = "0";
  const GOOGLE_PAY_MERCHANT_NAME = "Gatwick Airport";
  const [parametersArray, setParametersArray] = useState({});
  const [cartURL, setCartURL] = useState("");
  const [isCard, setIsCard] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [cardInfo, setCardInfo] = useState(null);
  const frontEndURL = "https://dropoff.gatwickairport.com";
  // const frontEndURL = 'https://f76c626e3853.ngrok.io'
  const forceUpdate = useForceUpdate();

  const config = {
    payments: {
      acceptedCardSchemes: ["amex", "masterCard", "maestro", "visa", "mada"],
    },
    shop: {
      product_price: purchase_amount,
      shop_name: GOOGLE_PAY_MERCHANT_NAME,
      shop_localisation: {
        currencyCode: "GBP",
        countryCode: "GB",
      },
    },
    shipping: {
      GB_region: [
        {
          label: "Free Shipping",
          amount: "0.00",
          detail: "Arrives in 3-5 days",
          identifier: "freeShipping",
        },
        {
          label: "Express Shipping",
          amount: "5.00",
          detail: "Arrives in 1-2 days",
          identifier: "expressShipping",
        },
      ],
      WORLDWIDE_region: [
        {
          label: "Worldwide Standard Shipping",
          amount: "10.00",
          detail: "Arrives in 5-8 days",
          identifier: "worldwideShipping",
        },
      ],
    },
  };

  useEffect(() => {
    forceUpdate();
    getPaymentMethod();
    payWithCreditClicked();
  }, []);

  async function getPaymentMethod() {
    try {
      let data = {
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      };

      const response = await requestPaymentMethod(data);
      if (response.data.status === "success") {
        if (response.data.data.length > 0) {
          Object.entries(response.data.data).map(([key, value]) => {
            // @ts-ignore
            if (value.default === 1) {
              // @ts-ignore
              setCardInfo(value);
              return setIsCard(true);
            }
          });
        }
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message);
      }
    }
  }

  async function registerGoogleGatewayPaymentToken(payment_token: any) {
    try {
      let data = {
        payment_token: payment_token,
        amount: purchase_amount,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      };

      const response = await registerGooglePaymentToken(data);

      if (response.data.status === "success") {
        getPaymentMethod();
        forceUpdate();
      }

      console.log("response", response.data);
    } catch (error) {
      console.log("error", error);
    }
  }

  async function payWithCreditClicked() {
    try {
      let data = {
        success_url: `${frontEndURL}/editpaymentdetails`,
        failure_url: `${frontEndURL}/editpaymentdetails`,
        return_url: `${frontEndURL}/editpaymentdetails`,
        description: "Gatwick Airport - AutoPay - Add Card",
        userlocale: configData.USERLOCALE,
        apikey: configData.APIKEY,
        amount: purchase_amount,
      };

      const response = await ekashuFormRequest(data);

      if (response.data.status === "success") {
        setParametersArray(response.data.data.parametersarray);
        setCartURL(response.data.data.cart_url);
      }

      console.log("response", response.data);
    } catch (error) {
      console.log("error", error);
    }
  }

  async function deletePaymentMethod() {
    setIsDeleteLoading(true);
    try {
      let data = {
        // @ts-ignore
        payment_method_id: cardInfo.id,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      };

      const response = await removePaymentMethod(data);

      if (response.data.status === "success") {
        setIsCard(false);
        setIsDeleteLoading(false);
        setShowDeleteDialog(false);
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message);
      }
    }
  }

  function getPaymentMethodIcon(pm: any) {
    switch (pm.brand) {
      case "MASTERCARD":
        return (
          <img
            src={mastercard}
            style={{ width: 50, height: 30, borderRadius: 10 }}
            alt="mastercard"
          />
        );

      case "VISA":
        return (
          <img
            src={visa}
            style={{ width: 50, height: 30, borderRadius: 10 }}
            alt="visa"
          />
        );
      case "AMEX":
        return (
          <img
            src={amex}
            style={{ width: 50, height: 30, borderRadius: 10 }}
            alt="amex"
          />
        );
      case "DISCOVER":
        return (
          <img
            src={discover}
            style={{ width: 50, height: 30, borderRadius: 10 }}
            alt="discover"
          />
        );
      case "DINERS":
        return (
          <img
            src={diners}
            style={{ width: 50, height: 30, borderRadius: 10 }}
            alt="diners"
          />
        );
      case "JCB":
        return (
          <img
            src={jcb}
            style={{ width: 50, height: 30, borderRadius: 10 }}
            alt="jcb"
          />
        );
      // case 'INTERAC':
      //   return (
      //     <img
      //       src={interac}
      //       style={{ width: 50, height: 30, borderRadius: 10 }}
      //       alt="interac"
      //     />
      //   )
      default:
        return (
          <img
            src={visa}
            style={{ width: 50, height: 30, borderRadius: 10 }}
            alt="visa"
          />
        );
    }
  }

  function renderCardUI() {
    // @ts-ignore
    const icon = getPaymentMethodIcon(cardInfo !== null ? cardInfo : "");
    return (
      <div
        style={{
          width: 250,
          height: 125,
          backgroundColor: "#004489",
          borderRadius: 25,
          marginBottom: 20,
          padding: 15,
          marginTop: 15,
        }}
      >
        <div>{icon}</div>

        <div style={{ marginTop: 20 }}>
          <p style={{ fontWeight: "bold", lineHeight: 0 }}>Card Number</p>

          <p>
            {
              // @ts-ignore
              ` xxxx xxxx xxxx ${cardInfo.last4}`
            }
          </p>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <p style={{ lineHeight: 0 }}>
            {
              // @ts-ignore
              `Expire: ${cardInfo.expiry_month}/${cardInfo.expiry_year}`
            }
          </p>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.root}>
      <Grid
        container
        spacing={0}
        justify={"center"}
        alignItems="center"
        direction="row"
      >
        <BodyHeader title="Payment Details" titleStep="" />

        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <div
              style={{
                marginTop: 20,
              }}
            >
              {isCard && (
                <div>
                  <label
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Selected Payment Method
                  </label>

                  <div style={{ display: "flex" }}>
                    {renderCardUI()}
                    {
                      // @ts-ignore
                      cardInfo.type === "googlepay" && (
                        <img src={googlePayIcon} alt="google pay" />
                      )
                    }
                  </div>
                  <Button
                    style={{
                      backgroundColor: "red",
                      textTransform: "none",
                      fontFamily: "conduit",
                    }}
                    onClick={() => setShowDeleteDialog(true)}
                  >
                    Delete payment method
                  </Button>

                  <div
                    style={{
                      backgroundColor: "gray",
                      height: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      margin: 25,
                    }}
                  />
                </div>
              )}

              <label
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Select a different Payment Method
              </label>

              <div className={classes.paymentStyle}>
                <form
                  id="cartform"
                  method="POST"
                  action={cartURL}
                  target="_parent"
                >
                  {Object.entries(parametersArray).map((value) => {
                    return (
                      <input
                        type="hidden"
                        key={value[0]}
                        name={value[0]}
                        id={value[0]}
                        // @ts-ignore
                        value={value[1] === null ? "" : value[1]}
                      />
                    );
                  })}

                  <input
                    type="submit"
                    value="Pay with Debit/Credit Card"
                    style={{
                      cursor: "pointer",
                      background: "#004489",
                      borderColor: "#004589",
                      width: "240px",
                      height: 40,
                      marginBottom:"20px",
                      borderRadius: 4,
                      color: "white",
                      fontWeight: "bold",
                      fontSize: 16,
                      borderWidth: 1,
                      fontFamily: "conduit",
                      WebkitAppearance: "none",
                    }}
                  />
                </form>

                <GooglePayButton
                  className={classes.googlePay}
                  environment="PRODUCTION"
                  paymentRequest={{
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [
                      {
                        type: "CARD",
                        parameters: {
                          allowedAuthMethods: ["CRYPTOGRAM_3DS"],
                          allowedCardNetworks: [
                            "AMEX",
                            "DISCOVER",
                            "INTERAC",
                            "JCB",
                            "MASTERCARD",
                            "VISA",
                          ],
                        },
                        tokenizationSpecification: {
                          type: "PAYMENT_GATEWAY",
                          parameters: {
                            gateway: "gatewayservices",
                            gatewayMerchantId: "25057313",
                          },
                        },
                      },
                    ],
                    merchantInfo: {
                      merchantId: "6954-5263-7142",
                      merchantName: "Gatwick Airport",
                    },
                    transactionInfo: {
                      totalPriceStatus: "FINAL",
                      totalPriceLabel: "Total",
                      totalPrice: purchase_amount,
                      currencyCode: config.shop.shop_localisation.currencyCode,
                      countryCode: config.shop.shop_localisation.countryCode,
                    },
                  }}
                  onLoadPaymentData={(paymentRequest) =>
                    registerGoogleGatewayPaymentToken(
                      paymentRequest.paymentMethodData.tokenizationData.token
                    )
                  }
                />
              </div>

              <ApplePay />
            </div>
          </Paper>
          <BottomButtons showSaveBtn={false} onBackClicked="/main" />
        </Grid>
        {showDeleteDialog && (
          <Dialog
            open={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              style={{ backgroundColor: "#004489" }}
            >
              Delete Payment Method
            </DialogTitle>
            <DialogContent style={{ backgroundColor: "#004489" }}>
              <DialogContentText></DialogContentText>
            </DialogContent>
            <DialogActions style={{ backgroundColor: "#004489" }}>
              <Button
                onClick={() => setShowDeleteDialog(false)}
                color="primary"
                style={{ color: "red" }}
              >
                Cancel
              </Button>
              {isDeleteLoading ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                <Button
                  onClick={() => deletePaymentMethod()}
                  style={{ color: "white" }}
                >
                  Delete
                </Button>
              )}
            </DialogActions>
          </Dialog>
        )}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      "@media (max-width: 675px)": {
        width: "100%",
      },
      width: "70%",
    },
    paper: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    paymentStyle: {
      "@media (max-width: 675px)": {
        display: "grid",
        justifyContent: "center",
      },
      marginTop: 20,
      display: "flex",
    },
    googlePay: {
      "@media (max-width: 675px)": {
        marginLeft: 0,
        marginTop: 20,
      },
      marginLeft: 50,
    },
    applePay: {
      "@media (max-width: 675px)": {
        display: "flex",
        justifyContent: "center",
      },
    },
  })
);
