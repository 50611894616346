import { Button, Typography } from '@material-ui/core';

import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import visa from '../../assets/card/visa.png';
import masterCard from '../../assets/card/mastercard.png';
import express from '../../assets/card/amex.png';
import payPal from '../../assets/card/paypal.png';

import GooglePay from '../../components/GooglePay/GooglePay';
import { ReactComponent as PaypalIcon } from '../../assets/icons/paypal.svg';
import ApplePay from '../../components/ApplePay/ApplePay';

const creditsCards = [ visa, masterCard, express, payPal ];

interface PaymentOptionsInterface {
	paymentComplite: () => void;
}

const PaymentOptions: React.FC<PaymentOptionsInterface> = ({ paymentComplite }) => {
	const classes = useStyles();

	return (
		<div>
			<Typography variant="h6">Payment Options</Typography>
			<div style={{ display: 'inline-flex' }}>
				{creditsCards.map((src) => (
					<div
						style={{
							margin: '0px 3px',
							width: '40px',
							height: '26px',
							border: '1px solid black',
							borderRadius: '5px',
							padding: '0px'
						}}
					>
						<img
							style={{
								width: '40px',
								height: '26px',

								padding: '0px'
							}}
							src={src}
							alt="card"
						/>
					</div>
				))}
			</div>
			<div className={classes.root}>
				<Button onClick={() => paymentComplite()} variant="contained" color="primary" disableElevation>
					Pay with Debit/Credit Card
				</Button>
				<Button variant="contained" color="primary" disableElevation>
					<SvgIcon style={{ paddingRight: '10px' }}>
						<PaypalIcon />
					</SvgIcon>
					Connect with PayPal
				</Button>
				<ApplePay />
				<GooglePay />
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			'& > *': {
				margin: theme.spacing(1)
			}
		}
	})
);

export default PaymentOptions;
