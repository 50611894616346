import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AppleIcon from '@material-ui/icons/Apple';
import { applePaySessionURL } from '../../services/api';
import configData from '../../screen/config/index.json';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const testmode = false;

export default function ApplePay() {
	const [ canRunApplePay, setCanRunApplePay ] = useState(false);
	const classes = useStyles();

	const purchase_amount = '0';
	const GOOGLE_PAY_MERCHANT_NAME = 'Gatwick Airport';

	useEffect(() => {
		canItRunApplePay();
	}, []);

	function canItRunApplePay() {
		if (window.location.origin === 'http://localhost:3000') {
			setCanRunApplePay(testmode);
			return;
		}
		// @ts-ignore
		if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
			setCanRunApplePay(true);
			return;
		}
		setCanRunApplePay(false);
	}

	const config = {
		payments: {
			acceptedCardSchemes: [ 'amex', 'masterCard', 'maestro', 'visa', 'mada' ]
		},
		shop: {
			product_price: purchase_amount,
			shop_name: GOOGLE_PAY_MERCHANT_NAME,
			shop_localisation: {
				currencyCode: 'GBP',
				countryCode: 'GB'
			}
		}
	};

	function onApplePayButtonClicked() {
		// @ts-ignore
		if (!window.ApplePaySession) {
			return;
		}

		// Define ApplePayPaymentRequest
		const request = {
			currencyCode: config.shop.shop_localisation.currencyCode,
			countryCode: config.shop.shop_localisation.countryCode,
			merchantCapabilities: [ 'supports3DS', 'supportsEMV', 'supportsCredit', 'supportsDebit' ],
			supportedNetworks: config.payments.acceptedCardSchemes,
			shippingType: 'shipping',
			requiredBillingContactFields: [ 'postalAddress', 'name' ],
			requiredShippingContactFields: [ 'postalAddress', 'name' ],
			total: {
				label: config.shop.shop_name,
				amount: config.shop.product_price,
				type: 'final'
			}
		};

		// Create ApplePaySession
		// @ts-ignore
		const session = new window.ApplePaySession(3, request);

		session.onvalidatemerchant = async (event: { validationURL: any }) => {
			// Call your own server to request a new merchant session.
			const merchantSession = await validateApplePaySession(event.validationURL);
			session.completeMerchantValidation(merchantSession);
		};

		session.onpaymentauthorized = () => {
			// Define ApplePayPaymentAuthorizationResult
			const result = {
				// @ts-ignore
				status: window.ApplePaySession.STATUS_SUCCESS
			};
			session.completePayment(result);
		};

		session.oncancel = () => {
			// Payment cancelled by WebKit
		};

		session.begin();
	}

	async function validateApplePaySession(url: any) {
		try {
			let data = {
				apikey: configData.APIKEY,
				userlocale: configData.USERLOCALE,
				validation_url: url
			};

			const response = await applePaySessionURL(data);

			console.log('validateApplePaySession response', response.data);

			return response.data;
		} catch (error) {
			console.log('validateApplePaySession error', error);
		}
	}

	return (
		<div>
			{canRunApplePay && (
				<div className={classes.applePay}>
					<Button
						onClick={() => onApplePayButtonClicked()}
						style={{
							width: 240,
							height: 40,
							backgroundColor: 'black',
							color: 'white',
							textTransform: 'none',
							fontWeight: 'bold',
							fontSize: 16
						}}
					>
						Pay with
						<IconButton aria-label="delete" style={{ padding: 0 }}>
							<AppleIcon />
						</IconButton>
						Pay
					</Button>
				</div>
			)}
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		applePay: {
			'@media (max-width: 675px)': {
				display: 'flex',
				justifyContent: 'center'
			}
		}
	})
);
