import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'

export default function CustomTextField({
  fieldTitle = '',
  showHelpButton = false,
  linkTo = '',
  linkTitle = '',
  justifyContent = 'space-between',
  textFieldSize = '80%',
  isPasswordField = false,
  showLabel = false,
  inputValue = '',
  titleColor = 'black',
  txtValue = '',
  showHide = '',
  onClick = () => {},
  ...props
}) {
  const classes = useStyles()

  return (
    <Grid item xs={false} className={classes.gridContainer}>
      <Paper className={classes.paperOther}>
        <div
          style={{
            display: 'flex',
            // backgroundColor: 'orange',
            padding: 3,
            justifyContent: justifyContent,
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
              // backgroundColor: 'gray',
            }}
          >
            <div
              style={{
                flex: 1,
                height: 20,
                marginTop: 1,
                display: 'flex',
                alignItems: 'center',
                // backgroundColor: 'pink',
              }}
            >
              <label
                style={{
                  fontSize: 12,
                  flex: 1,
                  fontWeight: 'bold',
                  color: titleColor,
                }}
              >
                {fieldTitle}
              </label>
            </div>

            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: showLabel ? 'flex-start' : 'center',
                alignItems: 'center',
                // backgroundColor: 'red',
              }}
            >
              {showLabel ? (
                <label
                  style={{
                    color: 'black',
                    fontSize: '0.8em',
                  }}
                >
                  {inputValue}
                </label>
              ) : (
                <CssTextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  required
                  value={txtValue}
                  style={{ width: textFieldSize }}
                  className={classes.txtStyle}
                  {...props}
                />
              )}
            </div>
            <div
              style={{
                flex: 0.8,
                // backgroundColor: 'blue',
              }}
            >
              {isPasswordField && (
                <Button
                  style={{ backgroundColor: 'transparent' }}
                  onClick={onClick}
                >
                  <label
                    style={{
                      color: 'gray',
                      fontSize: 12,
                      paddingRight: 10,
                      textTransform: 'none',
                      fontFamily: 'conduit',
                    }}
                  >
                    {showHide}
                  </label>
                </Button>
              )}
            </div>
          </div>
        </div>
        {showHelpButton && (
          <div
            style={{
              // backgroundColor: 'green',
              textAlign: 'end',
            }}
          >
            <Link
              to={linkTo}
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              }}
            >
              {linkTitle}
            </Link>
          </div>
        )}
      </Paper>
      {isPasswordField && (
        <div style={{ flex: 1, display: 'flex' }}>
          <div className={classes.extraContainer} />
          <div className={classes.txtPassStyle}>
            <label
              className={classes.lblSize}
              style={{
                lineHeight: 1,
                color: 'black',
              }}
            >
              Password must be more than 8 characters and contains 3 of the
              following&nbsp;
            </label>
            <label
              className={classes.lblSize}
              style={{
                lineHeight: 0.5,
                color: 'black',
              }}
            >
              uppercase, lowercase, number, symbol
            </label>
          </div>
          <div className={classes.extraContainer} />
        </div>
      )}
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      marginTop: 100,
    },
    gridContainer: {
      '@media (max-width: 675px)': {
        width: '100%',
      },
      width: '70%',
    },
    paper: {
      '@media (max-width: 675px)': {
        padding: theme.spacing(1),
      },
      padding: theme.spacing(6),
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    paperOther: {
      '@media (max-width: 675px)': {
        padding: 1,
      },
      backgroundColor: 'transparent',
      padding: 2,
      boxShadow: 'none',
    },
    txtStyle: {
      '@media (max-width: 675px)': {
        width: 200,
      },
      flex: 1,
      width: 275,
      height: 30,
      justifyContent: 'center',

      display: 'flex',
    },
    txtPassStyle: {
      // backgroundColor: 'orange',
      flex: 0.8,
      paddingLeft: 15,
      height: 40,
      '@media (max-width: 675px)': {
        width: 275,
        flex: 1,
      },
      width: '100%',
    },
    extraContainer: {
      '@media (max-width: 1200px)': {
        width: 0,
        flex: 0.01,
      },
      flex: 1,
      width: '100%',
    },
    lblSize: {
      '@media (max-width: 675px)': {
        fontSize: 9,
      },
      fontSize: 12,
      fontWeight: 'normal',
    },
  })
)

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F3F3F3',
      borderWidth: 2,
      borderRadius: 2,
      color: 'black',
      height: 30,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#004489',
    },
    '& .MuiOutlinedInput-input': {
      color: 'black',
      padding: 5,
    },
    '&:hover .MuiOutlinedInput-input': {
      color: 'black',
    },
  },
})(TextField)
